import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {BrowserRouter} from "react-router-dom";
import {store} from './app/store'
import {Provider} from 'react-redux'

ReactDOM.render(
    <Provider store={store}>

        <BrowserRouter>
            <App/>
        </BrowserRouter>

    </Provider>,
    document.getElementById('root')
);

reportWebVitals();
