
import React from "react";
import Navbar, {Nav} from "../../nav/Navbar";
import Brand from "../../brand/Brand";
import ButtonDefault from "../../button/button-default/ButtonDefault";


const menuContent = [
    {name: "About", to: "/", className: "desktopOnly mt-20"},
    {name: "Careers", to: "/careers", className: "desktopOnly mt-20"},
    {name: "Scout", to: "/scouts", className: "desktopOnly mt-20"},
    {name: "Book a call", to: "https://calendly.com/maria-from-lovelacedynamics/30min", className: "desktopOnly", button: true},
];

const DroowMenu = ({hamburger}) => {

    let $key = 0;
    const getSubMenu = (items) => {
        $key++;
        if (items.dropdownMenu) {
            return (<Nav.Dropdown name={items.name}
                                  key={$key}>{items.dropdownMenu.map(item => getSubMenu(item))}</Nav.Dropdown>);
        }
        if (items.button) {
            return (
                <li className="navItem" key={$key}>
                    <ButtonDefault button_color="btn-white" text={items.name} href={items.to} target="_blank"/>
                </li> 
            )
        }
        return (
            <Nav.Link className={items.className} to={items.to} key={$key}>{items.name}</Nav.Link>
        );
    }


    return (
        <Navbar>
            <Navbar.Brand to={"/"}>
                <Brand width="120px" height="34px"/>
            </Navbar.Brand>
            <Navbar.Collapse cover="Menu">
                <Nav className={"primary-nav-mobile"}>
                    {menuContent.map(item => getSubMenu(item))}
                </Nav>
            </Navbar.Collapse>
        </Navbar>
    );

}

DroowMenu.defaultProps = {
    hamburger: false
}


export default DroowMenu;